import * as React from 'react';
import './Table.css';
import ReactTable from 'react-table-6';
import 'react-table-6/react-table.css';
import { DateRangePicker} from 'rsuite';
import 'rsuite/DateRangePicker/styles/index.css';
import subDays from 'date-fns/subDays';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import addDays from 'date-fns/addDays';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import addMonths from 'date-fns/addMonths';
import { ProjectListComp } from './ProjectListComp';
import { Button, Label, Row} from 'reactstrap';
import { FaCloudDownloadAlt } from "react-icons/fa";
import { Tooltip } from 'react-tooltip'
import _ from 'lodash';
import { useState,useEffect } from 'react';
import { ModalComp } from '../../pages/Modal';
import { AddWorklog } from '../../pages/AddWorklog';
import { WorklogList } from '../WorklogList';
import { useAppDispatch, useAppSelector } from '../../hooks/redux-hooks';
import { fetchUserWorklog, fetchWorklog, fetchWorklogById ,fetchTeamLeadWorklog, fetchHolidayList, fetchUsersPublicHoliday, fetchPublicHoliday} from '../../store/UserAction';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from 'react-loader-advanced';
import BeatLoader from "react-spinners/BeatLoader";
import moment from "moment";
var qs = require('qs');
import FileSaver from 'file-saver';
import userSlice from '../../store/UserSlice';
import loginSlice from '../../store/LoginSlice';
import { UserListComp } from './UserListComp';
import { useNavigate } from 'react-router-dom';
import { withFixedColumnsScrollEvent } from 'react-table-hoc-fixed-columns'
import 'react-table-hoc-fixed-columns/lib/styles.css'
import { AddBreak } from '../../pages/AddBreak';
var qs = require('qs');

const Table: React.FC = () => {
  const [showModal, setShowModal] = useState(false)
  const [showListModal, setShowListModal] = useState(false)
  const dispatch=useAppDispatch()
  const worklogList = useAppSelector(state=>state.user.workLogList)
  const workLogUserList = useAppSelector(state=>state.user.workLogUserList)
  const workLogTeamLeadList = useAppSelector(state=>state.user.workLogTeamLeadList)
  const getWorklogFlag = useAppSelector(state => state.user.getWorklogFlag)
  const worklogUpdated = useAppSelector(state=>state.user.worklogUpdated);
  const worklogAdded= useAppSelector(state=>state.user.worklogAdded);
  const worklogDeleted= useAppSelector(state=>state.user.worklogDeleted);
  const errorMsg= useAppSelector(state=>state.user.errorWorklogMsg);
  const loading = useAppSelector(state=>state.user.loading);
  const [data,setData] = useState([])
  const worklogDetails = useAppSelector(state=>state.user.selectedWorkLog)
  const getWorklogByIdFlag = useAppSelector(state => state.user.getWorklogByIdFlag)
  const [filterList, setFilterList] = useState({fromDate : moment(startOfWeek(new Date(),{ weekStartsOn: 1})).format("DD/MM/YYYY"), toDate: moment(endOfWeek(new Date(), { weekStartsOn: 1 }), -6).format("DD/MM/YYYY"),projects:[],users:[]})
  const spinner4 =   <BeatLoader style={{display: 'block'}} color='#ffffff' loading={loading} size={15} aria-label="Loading Spinner" />
  const [workLogDetails,setWorklogDetails] = useState({})
  const navigate = useNavigate()
  const ReactTableFixedColumns = withFixedColumnsScrollEvent(ReactTable)
  const [showBreakModal, setShowBreakModal] = useState(false)
  const [users, setUsers] = useState([])
  const [date, setDate] = useState(moment(startOfWeek(new Date(),{ weekStartsOn: 1})).format("DD/MM/YYYY"))
  const [name, setName] = useState('')
  const [totalTime, setTotalTime] = useState('')
  const [todaysDate, setTodaysDate] = useState('')
  const [titleData, setTitleData] = useState({})
  const [projectActive,setProjectActive] = useState([])
  const [selectedRole,setSelectedRole] = useState(sessionStorage.getItem("selectedRole"))
  const holiday = useAppSelector(state=>state.user.holidayList)
  const getHolidayList = useAppSelector(state=>state.user.getHolidayList)
  const [holidayList, setHolidayList] = useState([])
  const usersPublicHolidayList = useAppSelector(state=>state.user.usersPublicHolidayList)
  const getPublicHolidayList = useAppSelector(state=>state.user.getPublicHolidayList)
  const publicHoliday = useAppSelector(state=>state.user.publicHolidayList)
  const getUsersPublicHolidayList = useAppSelector(state=>state.user.getUsersPublicHolidayList)
  const [publicHolidayList,setPublicHolidayList] = useState([])

  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };
  const userDataTeamLead = (data) =>{
    let arr = []
    data.forEach(element => {arr.push(element.value)});
    setUsers(arr)
    setFilterList({...filterList,users:arr})
    if(filterList['users'].length === 0 && JSON.parse(localStorage.getItem('user'))?.role === 'Team Lead'){
      let obj = {
        fromDate : filterList['fromDate'], 
        toDate: filterList['toDate'],
        users: arr,
        projects: filterList['projects'],
        uid: JSON.parse(localStorage.getItem('user'))?.id && JSON.parse(localStorage.getItem('user'))?.id
      }
        const queryParam = qs.parse(obj);
        dispatch<any>(fetchTeamLeadWorklog(queryParam));
        let holidayObj = {
          fromDate : filterList['fromDate'], 
          toDate:  filterList['toDate'],
          users: filterList['users'].length > 0 ? filterList['users'] : users,
        }
          dispatch<any>(fetchHolidayList(holidayObj));
          setHolidayList(holiday)
          if(sessionStorage.getItem("selectedRole") === 'admin'){
            dispatch<any>(fetchPublicHoliday());
            const combinedArray = Object.values(publicHoliday).flat();
            const uniqueArray = combinedArray.filter((value, index, self) =>
              index === self.findIndex((t) => t['id'] === value['id'])
            );
            setPublicHolidayList(uniqueArray)
          }
          else{
            let companyID = JSON.parse(localStorage.getItem('user'))?.company.id
            dispatch<any>(fetchUsersPublicHoliday(companyID))
          }
        }
      }
  useEffect(()=>{
    const currentYear = new Date().getFullYear();
    if(errorMsg !== ''){
      if(errorMsg == '401'){
        navigate('/login')
      }
      toast.error('Somthing went wrong', {
        position: "top-right",
      });
    }
    if(getWorklogByIdFlag === true){
      setWorklogDetails(worklogDetails)
    }
    if(getWorklogFlag){
      if(sessionStorage.getItem("selectedRole") === 'admin'){
        setData(worklogList)
      }
      else if(sessionStorage.getItem("selectedRole") === 'Team Lead'){
        setData(workLogTeamLeadList)
      }
      else{
        setData(workLogUserList)
      }
    }
    if(worklogUpdated === true){
      toast.success("Worklog updated successfully!", {
        position: "top-right",
      });
      setShowListModal(false)
      setShowBreakModal(false)
    }
    if(worklogAdded === true){
      toast.success("New worklog added successfully!", {
        position: "top-right",
      });
      setShowModal(false)
      setShowBreakModal(false)
    }
    if(worklogDeleted === true){
      toast.success("Worklog deleted successfully!", {
        position: "top-right",
      });
      setShowListModal(false)
      setShowBreakModal(false)
    }
    if(getPublicHolidayList){
      if(publicHoliday !== undefined){
        const combinedArray = Object.values(publicHoliday).flat();
        const uniqueArray = combinedArray.filter((value, index, self) =>
          index === self.findIndex((t) => t['id'] === value['id'])
        );
        setPublicHolidayList(uniqueArray)
      }
    }
    if(getUsersPublicHolidayList){
      setPublicHolidayList(usersPublicHolidayList)
    }
    if((filterList['fromDate'] !== '') && (filterList['toDate'] !== '')){
      if(sessionStorage.getItem("selectedRole") === 'admin'){
        const queryParamWorklog = qs.parse(filterList);
          dispatch<any>(fetchWorklog(queryParamWorklog));
          setData(worklogList)
          setDate(filterList['fromDate'])
          dispatch<any>(fetchHolidayList(queryParamWorklog));
          dispatch<any>(fetchPublicHoliday());
          const combinedArray = Object.values(publicHoliday).flat();
          const uniqueArray = combinedArray.filter((value, index, self) =>
            index === self.findIndex((t) => t['id'] === value['id'])
          );
          setPublicHolidayList(uniqueArray)
          setHolidayList(holiday)
      }
      else if(sessionStorage.getItem("selectedRole") === 'user' && JSON.parse(localStorage.getItem('user'))?.company !== null){
        let obj = {
          fromDate : filterList['fromDate'], 
          toDate: filterList['toDate'],
          uid: JSON.parse(localStorage.getItem('user'))?.id && JSON.parse(localStorage.getItem('user'))?.id
        }
           const queryParam = qs.parse(obj);
          dispatch<any>(fetchUserWorklog(queryParam));
          setData(workLogUserList)
          let holidayObj = {
            fromDate :formatDate(new Date(currentYear, 0, 1)), 
            toDate: formatDate(new Date(currentYear, 11, 31)),
            userId: JSON.parse(localStorage.getItem('user'))?.id && JSON.parse(localStorage.getItem('user'))?.id,
          }
          const queryParamHoliday = qs.parse(holidayObj);
          dispatch<any>(fetchHolidayList(queryParamHoliday));
          let companyID = JSON.parse(localStorage.getItem('user'))?.company?.id
          dispatch<any>(fetchUsersPublicHoliday(companyID))
          setHolidayList(holiday)
      }
      else if(filterList['users'].length > 0){ 
        let obj = {
          fromDate : filterList['fromDate'], 
          toDate: filterList['toDate'],
          users: filterList['users'],
          projects: filterList['projects'],
          uid: JSON.parse(localStorage.getItem('user'))?.id && JSON.parse(localStorage.getItem('user'))?.id
        }
        const queryParam = qs.parse(obj);
        dispatch<any>(fetchTeamLeadWorklog(queryParam));
        setData(workLogTeamLeadList)
      }
      if(getHolidayList){
        setHolidayList(holiday)
      }
      
    }
    
  },[dispatch,sessionStorage.getItem("selectedRole"),getWorklogFlag,worklogUpdated,worklogAdded,worklogDeleted,getWorklogByIdFlag])
  
    const getTotals = (arr,key) => {
        let totalHours = 0;
        let totalMinutes = 0;
        arr && arr.forEach(item => {
          let replaceItem = item?.Project !== 'Break' ? item[key]['total_time'] : '00:00'  ;
          let time = replaceItem?.replace(':', '.')
          const [hours, minutes] = time?.split('.')?.map(parseFloat)
          totalHours += hours
          totalMinutes += minutes;
        });
        totalHours += Math.floor(totalMinutes / 60);
        totalMinutes %= 60;
        return `${totalHours?.toString().padStart(2, '0')}:${totalMinutes?.toString().padStart(2, '0')}`;
      };
      const getTotals1 = (arr,key) => {
        let totalHours = 0;
        let totalMinutes = 0;
        data.forEach(item => {
          let replaceItem = item?.Project !== 'Break' ? item[key] : '00:00'  ;
          let time = replaceItem?.replace(':', '.')
          const [hours, minutes] = time?.split('.')?.map(parseFloat)
          totalHours += hours
          totalMinutes += minutes;
        });
        totalHours += Math.floor(totalMinutes / 60);
        totalMinutes %= 60;
        return `${totalHours?.toString().padStart(2, '0')}:${totalMinutes?.toString().padStart(2, '0')}`;
      };
      const handleSumForDay = (arr) => {
        let totalMinutes = 0;
        let totalHours = 0;
        arr.forEach(item => {
          if(item?.project_name !== 'Break'){
            const [hours, minutes] = item.time.split(':').map(Number);
              totalMinutes += minutes;
              totalHours += hours;
          }
        });
        if (totalMinutes >= 60) {
          totalHours += Math.floor(totalMinutes / 60);
          totalMinutes = totalMinutes % 60;
        }
        const formattedHours = String(totalHours).padStart(2, '0');
        const formattedMinutes = String(totalMinutes).padStart(2, '0');
        setTotalTime(`${formattedHours}:${formattedMinutes}`)
        setTitleData({...titleData, time:`${formattedHours}:${formattedMinutes}`})
      };
      const handleSumForDayUser = (arr) => {
        let totalMinutes = 0;
        let totalHours = 0;
        arr.forEach(item => {
          if(item?.project.name !== 'Break'){
            const [hours, minutes] = item.timeSpent.split(':').map(Number);
              totalMinutes += minutes;
              totalHours += hours;
          }
        });
        if (totalMinutes >= 60) {
          totalHours += Math.floor(totalMinutes / 60);
          totalMinutes = totalMinutes % 60;
        }
        const formattedHours = String(totalHours).padStart(2, '0');
        const formattedMinutes = String(totalMinutes).padStart(2, '0');
        setTotalTime(`${formattedHours}:${formattedMinutes}`)
        setTitleData({...titleData, time:`${formattedHours}:${formattedMinutes}`})
      };
      const changeKeyIfNotActive = (obj) => {
        const updatedData = Object.keys(obj).reduce((acc, key) => {
          // If the key's array contains an item with isActive false, rename the key
          if (obj[key].some(item => !item.project.isActive)) {
            acc[`${key} (Deactive)`] = obj[key];
          } else {
            acc[key] = obj[key];
          }
          return acc;
        }, {});
        setWorklogDetails(updatedData)
      };
    const handleOnDayClick = (val,date) =>{
      let userRole = sessionStorage.getItem("selectedRole")
      if(val.length !== 0){
        dispatch(userSlice.actions.reset())
        setShowListModal(true)
        let obj = {}
        if(userRole === 'admin'){
          val.forEach(item => {
            handleSumForDay(val)
            Object.keys(item).map(key => {
              key !== 'time' && key !== 'project_name' && key !== 'IsProjectActive' ? 
              item[key].map (el => {
                setName(el.user.name)
                setTitleData({...titleData, name: el.user.name})
              }
              ) : ''
           })
        })
        const dateRegex = /(\d{2})\/([a-zA-Z]{3})\/(\d{4})-([a-zA-Z]{3})/;
        const match = date.match(dateRegex);
        if (match) {
          const day = match[1];
          const month = match[2];
          const year = match[3];
         // Create a date string in a format that the Date constructor can parse
          const dateString = `${day} ${month} ${year}`;
          const date = new Date(dateString);
          // Format the date as dd.mm.yyyy
          const formatted = `${String(date.getDate()).padStart(2, '0')}.${String(date.getMonth() + 1).padStart(2, '0')}.${date.getFullYear()}`;
          setTodaysDate(formatted);
          setTitleData({...titleData, date: formatted})
        } 
        val.forEach((element) => (Object.assign(obj,element)));
          delete obj['project_name']
          delete obj['time']
          delete obj['IsProjectActive']
          changeKeyIfNotActive(obj)
        }
        else if(userRole === 'Team Lead'){
          val.forEach(item => {
            handleSumForDay(val)
            Object.keys(item).map(key => {
              key !== 'time' && key !== 'project_name' && key !== 'IsProjectActive' ? 
              item[key].map (el => {
                setName(el.user.name)
                setTitleData({...titleData, name: el.user.name})
              }
              ) : ''
           })
        })
        const dateRegex = /(\d{2})\/([a-zA-Z]{3})\/(\d{4})-([a-zA-Z]{3})/;
        const match = date.match(dateRegex);
        if (match) {
          const day = match[1];
          const month = match[2];
          const year = match[3];
         // Create a date string in a format that the Date constructor can parse
          const dateString = `${day} ${month} ${year}`;
          const date = new Date(dateString);
          // Format the date as dd.mm.yyyy
          const formatted = `${String(date.getDate()).padStart(2, '0')}.${String(date.getMonth() + 1).padStart(2, '0')}.${date.getFullYear()}`;
          setTodaysDate(formatted);
          setTitleData({...titleData, date: formatted})
        } 
          val.forEach((element) => (Object.assign(obj,element)));
          delete obj['project_name']
          delete obj['time']
          delete obj['IsProjectActive']
          changeKeyIfNotActive(obj)
        }
        else{
          val.forEach(item => {
            handleSumForDayUser(val)
                setName(item.user.name)
                setTitleData({...titleData, name: item.user.name})
        })
        const dateRegex = /(\d{2})\/([a-zA-Z]{3})\/(\d{4})-([a-zA-Z]{3})/;
        const match = date.match(dateRegex);
        if (match) {
          const day = match[1];
          const month = match[2];
          const year = match[3];
         // Create a date string in a format that the Date constructor can parse
          const dateString = `${day} ${month} ${year}`;
          const date = new Date(dateString);
          // Format the date as dd.mm.yyyy
          const formatted = `${String(date.getDate()).padStart(2, '0')}.${String(date.getMonth() + 1).padStart(2, '0')}.${date.getFullYear()}`;
          setTodaysDate(formatted);
          setTitleData({...titleData, date: formatted})
        } 
          val.forEach((element) => {
            const prop1 = element?.project?.name;
            obj[prop1] = val;
          })
          setWorklogDetails(obj)
        }
      
       setProjectActive(val)
      }
      else{
        setShowListModal(false)
      }
     }
     const parseDateString = (dateStr) => {
      // Split the string by '/'
      const [day, monthText, yearWithDay] = dateStr.split('/');
      
      // Remove the day of the week (e.g., "Sun") after the year
      const year = yearWithDay.split('-')[0];
    
      // Create a map of month abbreviations to their corresponding month numbers (0-indexed)
      const monthMap = {
        Jan: 0, Feb: 1, Mar: 2, Apr: 3, May: 4, Jun: 5,
        Jul: 6, Aug: 7, Sep: 8, Oct: 9, Nov: 10, Dec: 11
      };
    
      // Find the month number from the month abbreviation
      const month = monthMap[monthText];
      // Return the Date object
      return new Date(year, month, day);  // Month is 0-indexed
    };
    // Helper function to convert "dd/MM/yyyy" to a Date object
    const parseHolidayDate = (dateStr) => {
      const [day, month, year] = dateStr.split('/');
      return new Date(year, month - 1, day);  // Month is 0-indexed
    };
    
    // Function to check if a date falls within the holiday list
    const isDateInRange = (dateStr) => {
      const inputDate = parseDateString(dateStr);  // Convert "29/Sep/2024-Sun" to Date object
      return holiday.some(leave => {
        if(leave['halfDay'] === false){
        const fromDate = parseHolidayDate(leave.fromDate);  // Convert fromDate to Date
        const toDate = parseHolidayDate(leave.toDate);  
           // Convert toDate to Date
        return inputDate >= fromDate && inputDate <= toDate; 
        }
       // Check if input date falls in the range
      });
    };
    const parseCustomDateFormat = (dateString)=> {
      const months = {
        Jan: 0, Feb: 1, Mar: 2, Apr: 3, May: 4, Jun: 5,
        Jul: 6, Aug: 7, Sep: 8, Oct: 9, Nov: 10, Dec: 11
      };
    
      // Split the date string to get "23/Sep/2024"
      const [datePart] = dateString.split('-');
      
      // Further split "23/Sep/2024" to extract day, month, and year
      const [day, monthStr, year] = datePart.split('/');
    
      // Convert to a valid Date object using day, month, and year
      const month = months[monthStr]; // Convert month abbreviation to a number (0-indexed)
      
      return new Date(year, month, day);
    }
    // Helper function to parse "dd/mm/yyyy" format into a Date object
    const parseHolidayAdminDate = (dateString) => {
      const [day, month, year] = dateString.split('/');
    // Create a Date object: JavaScript months are 0-indexed, so subtract 1 from month
    return new Date(year, month - 1, day);
    }
    const  isDateInHoliday = (date, userName) => {
      const targetDate = parseCustomDateFormat(date);
      return holidayList.some(holiday => {
        if (holiday.userName === userName) {
          if(holiday['halfDay'] === false){
          const fromDate = parseHolidayAdminDate(holiday.fromDate);
          const toDate = parseHolidayAdminDate(holiday.toDate);
          // Check if the target date is within the holiday range
          return targetDate >= fromDate && targetDate <= toDate;
          }
        }
        return false;
      });
    }
    const isDateDisabledPublicHoliday = (date) => {
        const targetDate = parseCustomDateFormat(date);
        return usersPublicHolidayList.some(holiday => {
            const date = parseHolidayAdminDate(holiday.date);
            return targetDate.toDateString() === date.toDateString()
        });
    };
    const isDateDisabledPublicHolidayAdmin = (date,companyName) => {
      const targetDate = parseCustomDateFormat(date);
      return publicHolidayList.some(holiday => {
        if (holiday.companyName === companyName) {
          const date = parseHolidayAdminDate(holiday.date);
          return targetDate.toDateString() === date.toDateString()
        }
        return false;
      });
   };
   const convertTimeStringToMinutes = (time) => {
    const [hours, minutes] = time.split(":").map(Number);
    return hours * 60 + minutes;
   };
     const columns = Object.keys(data.length !== 0 ? data && data[0] : []).map((key) =>{
         return{
          Header :  () =>{ return(
            key !== 'Name' && key !== 'Total' ? 
            <><div style={{paddingBottom: '3px'}}>{key.slice(3, -9)}</div>
            <div style={{fontWeight: '200'}}>{key.slice(0, 3) + key.slice(-3)}</div></>  
            : <div>{key}</div>
          )}, 
          accessor : key,
          fixed: key === 'Name' || key === 'Total' ? 'left' : '',
          show: (key === 'user_id' || key === 'Company Name') ? false: true,
          getProps: (state, rowInfo, column) => {
            let isInHoliday = 
            column.id !== 'user_id' && 
            column.id !== 'Total' &&
            column.id !== 'Name' 
            ? isDateInHoliday(column.id,  rowInfo?.row.Name)
            : false;
            let isPublicHoliday = 
            column.id !== 'user_id' && 
            column.id !== 'Total' &&
            column.id !== 'Name' ?
            isDateDisabledPublicHolidayAdmin(column.id, rowInfo?.row['Company Name']) :
            false;
            let currentDate = `${new Date().toString().slice(8,10)}/${new Date().toString().slice(4,7)}/${new Date().toString().slice(11,15)}`
            if (rowInfo) {
              return {
                style: {
                  background:  (isPublicHoliday || isInHoliday )? '#ffe6e6' : column.id.includes('Sat')  ||  column.id.includes('Sun') || column.id.includes(new Date()) ? '#F0F0F0': column.id.includes(currentDate) ? '#e6ecff' : 'white',
                }
              }
            }
            return {
              style: {
                background: column.id.includes('Sat')  ||  column.id.includes('Sun') || column.id.includes(new Date()) ? '#F0F0F0' : column.id.includes(currentDate) ? '#e6ecff' : '' ,
                textAlign: column.id.includes('Name') ? 'left' : 'center'
              }
            }
          },
          getHeaderProps: (state, rowInfo, column) => {
            let currentDate = `${new Date().toString().slice(8,10)}/${new Date().toString().slice(4,7)}/${new Date().toString().slice(11,15)}`
            return {
              style: {
                background: column.id.includes('Sat')  ||  column.id.includes('Sun') ? '#F0F0F0' : column.id.includes(currentDate) ? '#e6ecff' : '',
                boxShadow : column.id.includes('Sat')  ||  column.id.includes('Sun') ? '0 0 5px 0 rgba(0, 0, 0, 0.1)':''
              }
            }
          },
          Footer: (props, row) =>{
            return(
              props.column.id !== "Name" && props.column.id !== 'user_id' && props.column.id !== 'Total' ?
                <span style={{color: getTotals(data,key) === '00:00' ? '#C8C8C8' : 'black', fontWeight: getTotals(data,key) === '00:00' ? '' : 'bold' }}>{getTotals(data,key)}</span>
                :
                props.column.id !== "Name" && <span  style={{color: getTotals1(data,key) === '00:00' ? '#C8C8C8' : 'black', fontWeight: getTotals1(data,key) === '00:00' ? '' : 'bold' }}>{getTotals1(data,key)}</span>
            )
          },
          Cell: (props) => {
            const projectArray =  props.column.id !== "Name" && props.column.id !== 'user_id' && props.column.id !== "Total" ? props?.row[key]?.projects?.map((item) => item.project_name + ' ' + '-' + ' '+ item.time) : ''
            const projectString = props.column.id !== "Name"  && props.column.id !== 'user_id' && props.column.id !== "Total" ? projectArray?.join() : ''
            const tooltipText = projectString?.split(",").join("\n")
            let isInHoliday = 
            props.column.id !== 'user_id' && 
            props. column.id !== 'Total' &&
            props.column.id !== 'Name'
            ? isDateInHoliday( props.column.id,   props?.row.Name)
            : false;
            let isPublicHoliday = 
            props.column.id !== 'user_id' && 
            props.column.id !== 'Total' &&
            props.column.id !== 'Name' ?
            isDateDisabledPublicHolidayAdmin(props.column.id, props?.row['Company Name']) :
            false;
            return(
              props.column.id !== "Name" && props.column.id !== 'user_id' && props.column.id !== "Total" ? 
            isInHoliday === true ?
              <a>On leave</a> : (props.row[key].total_time === '00:00' && isPublicHoliday) ?  <a>Holiday</a> :
            <><a data-tooltip-id="time-tooltip" data-tooltip-content={tooltipText !== undefined ? `${tooltipText}` : ''} className='number' style={{ cursor: tooltipText !== undefined ? 'pointer' : 'default', color: tooltipText !== undefined ? 'blue' : '#C8C8C8' }} onClick={() => handleOnDayClick(tooltipText !== undefined ? props.row[key].projects : [], props.column.id )}>{props.row[key].total_time}</a>
             <Tooltip  id="time-tooltip" style={{ backgroundColor: "#4775d1", color: "white" , whiteSpace:'pre-line' ,textAlign: 'right',zIndex: '9'}} place='right' />
            </>
            :
            props.column.id === "Name" ? 
            <a>{props.row.Name}</a>:
            <a style={{color:  props.row.Total !== '00:00' ? 'black' : '#C8C8C8'}}>{props.row.Total}</a>)}
         }
      })
      const columns1= Object.keys(data.length !== 0 ? data && data[0] : []).map((key) =>{
        return{
         Header :  () =>{ return(
           key !== 'Project' && key !== 'Total Logged Hrs' && key !== 'Allocated Hrs' && key !== 'Remaining Hrs' && key !== 'IsProjectActive'? 
           <><div style={{paddingBottom: '3px'}}>{key.slice(3, -9)}</div>
           <div style={{fontWeight: '200'}}>{key.slice(0, 3) + key.slice(-3)}</div></>  
           :  key === 'Total Logged Hrs'  ?
           <><div>{key.slice(0, 12)}</div>
           <div>{key.substring(key.length - 3)}</div></>
           : key === 'Allocated Hrs'  ?
           <><div>{key.slice(0, 9)}</div>
           <div>{key.substring(key.length - 3)}</div></>
          : key === 'Remaining Hrs'  ?
          <><div>{key.slice(0, 9)}</div>
          <div>{key.substring(key.length - 3)}</div></>:
          <div>{key}</div>
         )}, 
         accessor : key,
         fixed: key === 'Project' || key === 'Total Logged Hrs' ||  key === 'Allocated Hrs'  ||  key === 'Remaining Hrs' || key === 'IsProjectActive'? 'left' : '',
         show: key === 'user_id' ||  key === 'IsProjectActive' ? false: true,
         getProps: (state, rowInfo, column) => {
           let currentDate = `${new Date().toString().slice(8,10)}/${new Date().toString().slice(4,7)}/${new Date().toString().slice(11,15)}`
           let isInHolidayRange = 
           column.id !== "Project" && 
           column.id !== 'user_id' && 
           column.id !== 'Total Logged Hrs' &&
           column.id !== "Allocated Hrs" &&  
           column.id !== "Remaining Hrs" && 
           column.id !== 'IsProjectActive' 
           ? isDateInRange(column.id) 
           : false;
           let isPublicHoliday = 
           column.id !== "Project" && 
           column.id !== 'user_id' && 
           column.id !== 'Total Logged Hrs' &&
           column.id !== "Allocated Hrs" &&  
           column.id !== "Remaining Hrs" && 
           column.id !== 'IsProjectActive' 
           ? isDateDisabledPublicHoliday(column.id) 
           : false;
           if (rowInfo) {
            return {
              style: {
                background: rowInfo.row.IsProjectActive === false || column.id.includes('Sat') ||  column.id.includes('Sun')  ? '#F0F0F0' : column.id.includes(currentDate) && isInHolidayRange === false && isInHolidayRange === false && isPublicHoliday === false ? '#e6ecff'  :  isInHolidayRange === true || isPublicHoliday === true ? '#ffe6e6' : 'white',
              }
            }
          }
            return {
              style: {
                background: column.id.includes('Sat')  ||  column.id.includes('Sun') || column.id.includes(new Date()) || column.id.includes('Project') === "false" ? '#F0F0F0' : column.id.includes(currentDate) && isInHolidayRange === false && isPublicHoliday === false ? '#e6ecff' : isInHolidayRange === true || isPublicHoliday === true? '#ffe6e6' : 'white' ,
                textAlign: column.id.includes('Project') ? 'left' : 'center'
              }
            }
         },
        
         getHeaderProps: (state, rowInfo, column) => {
          let isInHolidayRange = 
          column.id !== "Project" && 
          column.id !== 'user_id' && 
          column.id !== 'Total Logged Hrs' &&
          column.id !== "Allocated Hrs" &&  
          column.id !== "Remaining Hrs" && 
          column.id !== 'IsProjectActive' 
          ? isDateInRange(column.id) 
          : false;
           let isPublicHoliday = 
           column.id !== "Project" && 
           column.id !== 'user_id' && 
           column.id !== 'Total Logged Hrs' &&
           column.id !== "Allocated Hrs" &&  
           column.id !== "Remaining Hrs" && 
           column.id !== 'IsProjectActive' 
           ? isDateDisabledPublicHoliday(column.id) 
           : false;
           let currentDate = `${new Date().toString().slice(8,10)}/${new Date().toString().slice(4,7)}/${new Date().toString().slice(11,15)}`
           return {
             style: {
               background: column.id.includes('Sat')  ||  column.id.includes('Sun') ? '#F0F0F0' : column.id.includes(currentDate) && isInHolidayRange === false && isPublicHoliday === false? '#e6ecff' : isInHolidayRange === true || (isPublicHoliday === true) ? '#ffe6e6': '',
               boxShadow : column.id.includes('Sat')  ||  column.id.includes('Sun') ? '0 0 5px 0 rgba(0, 0, 0, 0.1)':''
             }
           }
         },
         Footer: (props, row) =>{
           return(
             props.column.id !== "Project" && props.column.id !== 'user_id' && props.column.id !== 'Total Logged Hrs'  && props.column.id !== "Allocated Hrs" &&  props.column.id !== "Remaining Hrs" && props.column.id !== 'IsProjectActive'?
               <span style={{color: getTotals(data,key) === '00:00' ? '#C8C8C8': convertTimeStringToMinutes(getTotals(data,key)) >= convertTimeStringToMinutes("14:00") ? 'red' :  'black', fontWeight: getTotals(data,key) === '00:00' ? '' : 'bold' }}>{getTotals(data,key)}</span>
               :
               props.column.id !== "Project" && <span  style={{color: getTotals1(data,key) === '00:00' ? '#C8C8C8' : 'black', fontWeight: getTotals1(data,key) === '00:00' ? '' : 'bold' }}>{getTotals1(data,key)}</span>
           )
         },
         Cell: (props) => {
          let isInHolidayRange = 
          props.column.id !== "Project" && 
          props.column.id !== 'user_id' && 
          props.column.id !== 'Total Logged Hrs' &&
          props.column.id !== "Allocated Hrs" &&  
          props.column.id !== "Remaining Hrs" && 
          props.column.id !== 'IsProjectActive' 
          ? isDateInRange(props.column.id) 
          : false;
           let isPublicHoliday = 
           props.column.id !== "Project" && 
           props.column.id !== 'user_id' && 
           props.column.id !== 'Total Logged Hrs' &&
           props.column.id !== "Allocated Hrs" &&  
           props.column.id !== "Remaining Hrs" && 
           props.column.id !== 'IsProjectActive' 
           ? isDateDisabledPublicHoliday(props.column.id) 
           : false;
           return(
            props.column.id !== "Project" && props.column.id !== 'user_id' && props.column.id !== "Total Logged Hrs" && props.column.id !== "Allocated Hrs" && props.column.id !== "Remaining Hrs" && props.column.id !== 'IsProjectActive' && isInHolidayRange === false ? 
            <><a  className='number' style={{ cursor: props.row[key].total_time !== '00:00' && props.row['IsProjectActive'] !== "false" ? 'pointer' : 'default', color: props.row[key].total_time !== '00:00' && props.row['IsProjectActive'] !== "false" ? 'blue' : '#A8A8A8' }} onClick={() => props.row['IsProjectActive'] !== "false" ? handleOnDayClick(props.row[key].logs,props.column.id) : ''}>{props.row[key].total_time}</a>
            </>
            :
             props.column.id !== "Project" && props.column.id !== 'user_id' && props.column.id !== "Total Logged Hrs" && props.column.id !== "Allocated Hrs" && props.column.id !== "Remaining Hrs" && props.column.id !== 'IsProjectActive' && isInHolidayRange === false && isPublicHoliday &&  props.row[key].total_time === '00:00'?
             <a>Holiday</a>
            :
            isPublicHoliday ? 
            <a>Holiday</a> :
           isInHolidayRange ?
           <a>On leave</a> :
           props.column.id === "Project" ? 
           <a>{props.row.Project}</a> :
          <a>{props.row[key]}</a> )}
        }
     })
      const predefinedRanges = [
        {
          label: 'Today',
          value: [new Date(), new Date()],
          placement: 'left'
        },
        {
          label: 'Yesterday',
          value: [addDays(new Date(), -1), addDays(new Date(), -1)],
          placement: 'left'
        },
        {
    
          label: 'This week',
          value: [startOfWeek(new Date(),{ weekStartsOn: 1}), endOfWeek(new Date(), { weekStartsOn: 1 }), -6],
          placement: 'left'
        },
        {
          label: 'Last 7 days',
          value: [subDays(new Date(), 6), new Date()],
          placement: 'left'
        },
        {
          label: 'Last 30 days',
          value: [subDays(new Date(), 29), new Date()],
          placement: 'left'
        },
        {
          label: 'This month',
          value: [startOfMonth(new Date()), new Date()],
          placement: 'left'
        },
        {
          label: 'Last month',
          value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))],
          placement: 'left'
        },
        {
          label: 'Last week',
          closeOverlay: false,
          value: value => {
            const [start = new Date()] = value || [];
            return [
              addDays(startOfWeek(start, { weekStartsOn: 1}), -7),
              addDays(endOfWeek(start, { weekStartsOn: 1 }), -7)
            ];
          },
          appearance: 'default'
        },
        {
          label: 'Next week',
          closeOverlay: false,
          value: value => {
            const [start = new Date()] = value || [];
            return [
              addDays(startOfWeek(start, { weekStartsOn: 1 }), 7),
              addDays(endOfWeek(start, { weekStartsOn: 1}), 7)
            ];
          },
          appearance: 'default'
        }
      ];
    const openEditModal = () =>{
        setShowModal(true)
    }
    const openBreakModal = () =>{
      setShowBreakModal(true)
  }
    const closeModal = () =>{
      setShowModal(false)
      setShowListModal(false)
      setShowBreakModal(false)
    }
    const projectSelection = (data) =>{
       let arr = []
       data.forEach(element => {arr.push(element.value)});
       setFilterList({...filterList,projects:arr})
    }
    const userSelection = (data) =>{
      let arr = []
      data.forEach(element => {arr.push(element.value)});
      setFilterList({...filterList,users: arr.length > 0 ? arr : users})
   }
    const dateChange = (e) =>{
      dispatch(userSlice.actions.reset())
      
      if(e && e?.length !== 0){
        setDate(moment(e[0]).format("DD/MM/YYYY"))
        let startDate = moment(e[0]).format("DD/MM/YYYY");
        let toDate = moment(e[1]).format("DD/MM/YYYY")
        setFilterList({...filterList ,fromDate : startDate, toDate: toDate})
        if(sessionStorage.getItem("selectedRole") === 'admin'){
          let obj = {
            fromDate : startDate, toDate: toDate,
            users : filterList['users'].length === 0 ? null : filterList['users']
          }
          let holidayObj = {
            fromDate : startDate, 
            toDate: toDate,
          }
          const queryParam = qs.parse(obj);
            dispatch<any>(fetchWorklog(queryParam));
            setData(worklogList)
            dispatch<any>(fetchHolidayList(holidayObj));
            setHolidayList(holiday)
        }
        else if(sessionStorage.getItem("selectedRole") === 'Team Lead'){
          let obj = {
            fromDate : startDate, 
            toDate: toDate,
            projects: filterList['projects'],
            users: filterList['users'].length > 0 ? filterList['users'] : users,
            uid: JSON.parse(localStorage.getItem('user'))?.id && JSON.parse(localStorage.getItem('user'))?.id
          }
          let holidayObj = {
            fromDate : startDate, 
            toDate: toDate,
            users: filterList['users'].length > 0 ? filterList['users'] : users,
          }
            const queryParam = qs.parse(obj);
            dispatch<any>(fetchTeamLeadWorklog(queryParam));
            dispatch<any>(fetchHolidayList(holidayObj));
            setHolidayList(holiday)
        }
        else{
          let obj = {
            fromDate :startDate, 
            toDate: toDate,
            uid: JSON.parse(localStorage.getItem('user'))?.id && JSON.parse(localStorage.getItem('user'))?.id
          }
          const queryParam = qs.parse(obj);
            dispatch<any>(fetchUserWorklog(queryParam));
        }
      }
      else{
        setFilterList({...filterList ,fromDate : '', toDate: ''})
        setData([])
      }
    }
    const onProjectClose = () =>{
      dispatch(userSlice.actions.reset())
      let userRole = JSON.parse(localStorage.getItem('user'))?.role
      let obj = {
        fromDate : filterList['fromDate'], 
        toDate: filterList['toDate'],
        users: JSON.parse(localStorage.getItem('user'))?.id && JSON.parse(localStorage.getItem('user'))?.id
      }
      const queryParam = qs.parse(userRole == 'admin' ? filterList : obj);
    //   dispatch<any>(fetchWorklog(queryParam));
    }
    const onUserClose = () =>{
      dispatch(userSlice.actions.reset())
    //   if(userRole === 'admin'){
    //     let obj = {
    //       fromDate : filterList['fromDate'], 
    //       toDate: filterList['toDate'],
    //       projects : filterList['projects'],
    //       users : filterList['users'].length === 0 ? null : filterList['users']
    //     }
    //     const queryParam = qs.parse(obj);
    //     dispatch<any>(fetchWorklog(queryParam));
    //   }
    //   else{
    //     let obj = {
    //       fromDate : filterList['fromDate'], 
    //       toDate: filterList['toDate'],
    //       projects: filterList['projects'],
    //       users: filterList['users'].length > 0 ? filterList['users'] : users,
    //       uid: JSON.parse(localStorage.getItem('user'))?.id && JSON.parse(localStorage.getItem('user'))?.id
    //     }
    //       const queryParam = qs.parse(obj);
    //       dispatch<any>(fetchTeamLeadWorklog(queryParam));
    //  }
   }
    const FileExportExcel = () => {
      let filename = 'Worklog_'+filterList['fromDate']+'_'+filterList['toDate']
      dispatch(userSlice.actions.setLoading())
      let user = JSON.parse(localStorage.getItem('user'))
      var qs = require('qs');
      var xhr = new XMLHttpRequest();
      let api ;
      if( user.role === 'admin'){
         api = 'https://worklog.meconit.in:8444/work-log-services/api/v1/workLog/filter/export/xsl?'+qs.stringify(filterList, {arrayFormat: 'repeat'});

      }
      else if(user.role === 'Team Lead'){
        let obj = {
          fromDate : filterList['fromDate'], 
          toDate: filterList['toDate'],
          projects: filterList['projects'],
          users: filterList['users'].length > 0 ? filterList['users'] : users,
          uid: JSON.parse(localStorage.getItem('user'))?.id && JSON.parse(localStorage.getItem('user'))?.id
        }
        api = 'https://worklog.meconit.in:8444/work-log-services/api/v1/workLog/tl/filter/export/xsl?'+qs.stringify(obj, {arrayFormat: 'repeat'});
      }
      else{
        let obj = {
          fromDate : filterList['fromDate'], 
          toDate: filterList['toDate'],
          uid: JSON.parse(localStorage.getItem('user'))?.id && JSON.parse(localStorage.getItem('user'))?.id
        }
        api = 'https://worklog.meconit.in:8444/work-log-services/api/v1/workLog/user/filter/export/xsl?'+qs.stringify(obj, {arrayFormat: 'repeat'});

      }
      xhr.open('GET',api);
      xhr.setRequestHeader('Cache-Control','no-cache');
      xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
      xhr.setRequestHeader('Authorization', `Bearer ${JSON.parse(localStorage.getItem('user')).token}`);
      xhr.responseType = 'blob';
      xhr.onload = async function () {
       if(xhr.status === 200){
         await  FileSaver.saveAs(xhr.response, filename+'.xlsx');
         dispatch(userSlice.actions.setFinishLoading())
         toast.success("Excel downloaded successfully!", {
          position: "top-right",
        });
       }
       else if(xhr.status === 500){
        toast.error("Something went wrong", {
          position: "top-right",
        });
        dispatch(userSlice.actions.setFinishLoading())
       }
       else if (xhr.status === 401){
         dispatch(userSlice.actions.setFinishLoading())
         dispatch(loginSlice.actions.logout())
       }
       else{
        toast.error("Something went wrong", {
          position: "top-right",
        });
        dispatch(userSlice.actions.setFinishLoading())
       }
      }.bind(this);
      xhr.send();
    }

    const tableData = [{
      firstName: "test",
      lastName: "test",
      age: 23,
      visits: 34,
      progress: 56,
    }]
    let formTemplate = <AddWorklog formType="add"  rowData={{}} closeModal={closeModal}/>
    let listFormTemplate = <WorklogList worklogList={workLogDetails} projectActive = {projectActive} closeModal={closeModal}/>
    let BreakFormTemplate = <AddBreak formType="add"  rowData={{}} closeModal={closeModal}/>
    const initialState = { hiddenColumns: ['UserId'] };
    const userRole = JSON.parse(localStorage.getItem("user"))?.role
    return (
        <div className='adminTable'>
          <ToastContainer />
           {showModal === true &&
        <ModalComp isOpen={showModal} modalTitle='Add Worklog Details' id="addWorklog" modalBody={formTemplate} toggle={closeModal}/>
        }
           {showBreakModal === true &&
        <ModalComp isOpen={showBreakModal} modalTitle='Add Break' id="breakModal" modalBody={BreakFormTemplate} toggle={closeModal} />
        }
            {showListModal === true &&
        <ModalComp isOpen={showListModal} modalTitle='worklogTitle' id='worklogDetails' modalBody={listFormTemplate} toggle={closeModal} titleData={{'name': name , 'date' : todaysDate , 'total' : totalTime }}/>
        }
            <div className='adminDatePicker'>
                <Row>
                    <div className='col-sm-2' style={{paddingTop: '5px'}}>
                      <Row>
                     <Label style={{fontSize:'14px', color:'#707070', fontWeight: '600'}}>Select Date</Label>        
                     </Row>
                     <Row>
                      <DateRangePicker
                            ranges={predefinedRanges}
                            placeholder="Select date"
                            size='md'
                            isoWeek
                            cellClassName = {date => (date.getDay() % 2 ? 'bg-gray' : undefined)} 
                            defaultValue={[startOfWeek(new Date(),{ weekStartsOn: 1}), endOfWeek(new Date(), { weekStartsOn: 1 }), -6]}
                            onChange={dateChange}
                          />
                          </Row>
                     </div>
                     {((sessionStorage.getItem("selectedRole") === 'admin') || (sessionStorage.getItem("selectedRole") === 'Team Lead')) && 
                    <div className='col-sm-2' style={{zIndex: '9'}}> 
                    <Label style={{fontSize:'14px', color:'#707070', fontWeight: '600'}}>Select Project</Label>
                    <ProjectListComp date = {date} onMenuClose={onProjectClose} onProjectSelection = {projectSelection}/></div>}
                    {((sessionStorage.getItem("selectedRole") === 'admin') || (sessionStorage.getItem("selectedRole") === 'Team Lead'))&& <div className='col-sm-2'  style={{zIndex: '9'}}>
                    <Label style={{fontSize:'14px', color:'#707070', fontWeight: '600'}}>Select User</Label> <UserListComp onMenuClose={onUserClose} onUserSelection = {userSelection} userDataTeamLead = {userDataTeamLead}/></div>}
                      <div className='col-sm-1 download'>
                          <a data-tooltip-id="my-tooltip" data-tooltip-content="Download excel" style={{cursor: 'pointer'}}>
                            <FaCloudDownloadAlt id='downloadIcon' onClick={FileExportExcel}/>
                          </a>
                          <Tooltip id="my-tooltip"style={{ backgroundColor: "#4775d1", color: "white" }} place='right' />
                      </div>
                      {((sessionStorage.getItem("selectedRole") !== 'admin') && (sessionStorage.getItem("selectedRole") !== 'Team Lead')) && 
                      <><div className='col-sm-3 addBtn' style={{ marginLeft: sessionStorage.getItem("selectedRole") === 'user' ? '37%' : '4%' }}>
                <Button color="secondary" onClick={openBreakModal} style={{ marginRight: '10px' }}>Add Break</Button>
              </div><div className='col-sm-1 addBtn' style={{ width: '12%' }}>
                  <Button color="secondary" onClick={openEditModal} style={{ marginRight: '10px' }}>Add Worklog</Button>
                </div></>
                     }
                    </Row>
   
            </div>
        <Loader message={spinner4} show={loading}>   
        <ReactTableFixedColumns
            data={data}
            className={data.length === 0 ? 'worklogTable' : ''}
            columns={((selectedRole === 'admin') || (selectedRole === 'Team Lead')) ? columns : columns1 }
            defaultPageSize={((sessionStorage.getItem("selectedRole") === 'admin') || sessionStorage.getItem("selectedRole") === 'Team Lead') ? 10 : '5'}
            {...initialState}
            // showPaginationBottom={false}
        />
        </Loader>
        </div>
    ) 
};
export default Table
