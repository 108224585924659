import React, { Fragment, useEffect, useState } from 'react'
import { FormGroup, Input, Label,Form, Button, FormFeedback, Row, Col } from 'reactstrap'
import { Typeahead } from 'react-bootstrap-typeahead'
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { useAppDispatch, useAppSelector } from '../hooks/redux-hooks';
import { addProject, editProject, fetchUser, fetchVerifiedTeamLeadUser, fetchVerifiedUser } from '../store/UserAction';
import DatePicker, { registerLocale } from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import './CreateProject.css'
import moment from 'moment';

type Props = {
  formType : string;
  rowData ?: any;
  addTableData ?: any;
  closeModal ?: any;
}
type FormDataProps ={
  id ?: any;
  name ?: any;
  projectLead ?: any;
  teamMembers?: any;
  allocatedHrs ?: any;
  startDate ?: any;
  endDate ?: any;
}
type TableDataProps ={
  id ?: any;
  name ?: any;
  allocatedHrs ?: any;
}

export const CreateProject: React.FC<Props>  = ({formType,rowData,addTableData, closeModal}: Props) => {
const [data, setData] =useState<FormDataProps>(rowData);
const [projectNameValid, setValidProjectName] = useState(false);
const [projectLeadValid, setValidProjectLead] = useState(false)
const [projectMemberValid, setValidProjectMember] = useState(false)
const getVerifiedUsers = useAppSelector(state=>state.user.getVerifiedUsers);
const user = useAppSelector(state=>state.user.verifiedUsers)
const dispatch=useAppDispatch()
const loading = useAppSelector(state=>state.user.loading);
const [isOpenProject,setIsOpenProject] = useState(false)
const [isOpenUser,setIsOpenUser] = useState(false)
const [employeeData, setEmployeeData] = useState([])
const [teamMemberData, setTeamMemberData] = useState([])
const [showTeamMemberTable, setTeamMemberTable] = useState(false)
const [projectLead,setProjectLead] = useState([])
const [showInputErr, setShowInputErr] = useState(false)
const [errMsg, setErrMsg] = useState('')
const [allocatedHrs ,setAllocatedHrs] = useState(null)
const getVerifiedTeamLeadUsers = useAppSelector(state=>state.user.getVerifiedTeamLeadUsers);
const userForTeamLead = useAppSelector(state=>state.user.verifiedTeamLeadUsers)
const [userList, setUserList] = useState([])

useEffect(() =>{
  if(getVerifiedUsers){
   setUserList(user)
  }
  else if(getVerifiedTeamLeadUsers){
    setUserList(userForTeamLead)
  }

  if(data.projectLead !== undefined){
    let empArr = [...employeeData]
    empArr.push(data?.projectLead?.user)
    setEmployeeData(empArr)
  }
  if(data.allocatedHrs !== undefined){
      const parts = data.allocatedHrs.split(':');
      const mins = parseInt(parts[0], 10);
      setAllocatedHrs(mins)
  }
  if(data.teamMembers !== undefined){
   // const member =  data?.teamMembers?.find((item ,index) => item.projectLead === true)
    const member2 =  data?.teamMembers?.map((item ,index) => item.user)
    member2.push(data?.projectLead?.user)
    setProjectLead([data?.projectLead?.user])
    setTeamMemberTable(true)
    const modifiedEmployees = data?.teamMembers?.map(obj => {
      const parts = obj?.allocatedHrs?.split(':');
      const mins = parseInt(parts[0], 10);
      return { ...obj, allocatedHrs: mins };
    });
    let projectLead = data?.projectLead?.allocatedHrs.split(':');
    const mins = parseInt(projectLead[0], 10);
    let projectLeadObj = {...data.projectLead, allocatedHrs: mins}
    modifiedEmployees.push(projectLeadObj)
    setTeamMemberData(modifiedEmployees)
    setEmployeeData(member2)
  }
  if(JSON.parse(localStorage.getItem('user'))?.role === 'Team Lead'){
    dispatch<any>(fetchVerifiedTeamLeadUser());
    setUserList(userForTeamLead)
  }
  else if(JSON.parse(localStorage.getItem('user'))?.role === 'admin'){
      dispatch<any>(fetchVerifiedUser());
      setUserList(user)
  }
},[dispatch,getVerifiedUsers,getVerifiedTeamLeadUsers])

const onSubmit = (e) =>{
  e.preventDefault()
  let flag = false
  let totalHrs = data?.teamMembers !== undefined ? data?.teamMembers.reduce((a,v) =>  a = parseInt(a) + parseInt(v.allocatedHrs) , 0 ) : null
  if(parseInt(data.allocatedHrs) < totalHrs){
    setShowInputErr(true)
    flag = true
    setErrMsg('Allocated hours for project should not be less than total allocated hours for team members')
   }
   else{
    setShowInputErr(false)
    flag = false
    setErrMsg('')
   }
  if(data?.name === undefined){
    flag = true
    setValidProjectName(true)
  } 
  if(data?.projectLead === undefined){
    flag = true
    setValidProjectLead(true)
  } 

  if(!flag){
    if(formType === 'edit'){
      let Hrs = data.allocatedHrs.concat(':00')
      let HrsFlag = data.allocatedHrs.includes(":00")
      const projectLeadArr = data.teamMembers.filter(obj => obj.user.name === data.projectLead.user.name)
      const modifiedMembers = data.teamMembers.filter(item => item.user.name !== data.projectLead.user.name)
      let obj = {
        ...data,
          projectLead : data.projectLead !== undefined ? {
          ...projectLeadArr[0],
            id: data.id,
            project:{
              id: data?.id
            },
            user:{
              id: data.projectLead.user.id,
            },
             allocatedHrs: data?.projectLead !== undefined ? data?.projectLead.allocatedHrs.toString().includes(":00") ? data?.projectLead?.allocatedHrs : data?.projectLead?.allocatedHrs.toString().concat(':00') : '00:00'
          } : {},
          allocatedHrs : HrsFlag ? data.allocatedHrs : Hrs,
          teamMembers : modifiedMembers.map(function(item){ 
          delete item.name; 
          return {...item,  
            allocatedHrs : ((item.allocatedHrs !== null) && (item.allocatedHrs !== undefined)) ?  item.allocatedHrs.toString().includes(":00") ? item.allocatedHrs : item.allocatedHrs.toString().concat(':00') : '00:00',
            project: {
            id: data?.id
          }}; 
        })
      } 
   dispatch<any>(editProject(obj));
    }
    else{
      let arr = [data.projectLead]
      let Hrs = data.allocatedHrs !== undefined ? data.allocatedHrs.concat(':00') : ''
      let HrsFlag = data.allocatedHrs !== undefined ? data.allocatedHrs.includes(":00") : ''
      const projectLeadArr = teamMemberData.filter(obj => obj.user.name === data.projectLead.user.name)
      const modifiedMembers = data.teamMembers !== undefined ? data.teamMembers.filter(item => item.user.name !== data.projectLead.user.name) : []
     // teamMemberData.forEach(o1 => o1.projectLead = arr.filter(o2 => o2.id === o1.id)[0]? true : false); 
      let obj = {
        ...data,
        projectLead :  data.projectLead !== undefined ? {
          ...data.projectLead,
          allocatedHrs: projectLeadArr[0].allocatedHrs.toString().includes(":00") ? projectLeadArr[0].allocatedHrs : projectLeadArr[0].allocatedHrs.toString().concat(':00') 
        } : {},
        allocatedHrs : data.allocatedHrs !== undefined ? HrsFlag ? data.allocatedHrs : Hrs : '00:00',
        teamMembers : modifiedMembers.length !== 0 ? modifiedMembers.map(function(item){ 
          delete item.name; 
          delete item.id; 
          return {
            ...item,
            allocatedHrs :  ((item.allocatedHrs !== null)&&(item.allocatedHrs !== undefined)) ? item.allocatedHrs.includes(":00") ? item.allocatedHrs : item.allocatedHrs.concat(':00') : '00:00'
          }
        }) : []
      }
       dispatch<any>(addProject(obj));
    }
  }

}
const handleLeadClick = (e) =>{
  if(e[0] !== undefined){
    if(formType === 'edit'){
    let empArr = [...employeeData]
    let arr = [...teamMemberData]
    let obj = {
      name: e[0].name,
      user: {
        id: e[0].id,
        name: e[0].name,
      } ,
      allocatedHrs: '00'
    }
    empArr.push(e[0])
    arr.push({name: e[0].name, user: {id: e[0].id,name: e[0].name}, allocatedHrs : '00'})
    setEmployeeData(empArr)
    setValidProjectLead(false)
    setTeamMemberData(arr)
    setProjectLead([obj])
    setData({...data, projectLead: obj})
  }
  else{
      let obj = {
        id: e[0]?.id,
        name: e[0].name,
        user: {
          id: e[0].id,
          name: e[0].name,
        } ,
        allocatedHrs: '00'
      }
   
      setValidProjectLead(false)
      setData({...data, projectLead: obj})
      setProjectLead([obj])
      setEmployeeData(e)
      setTeamMemberData([{name: e[0].name, id: e[0].id, user: {id: e[0].id,name: e[0].name}, allocatedHrs : '00'}])
      setTeamMemberTable(true)
  }
   
  }
  else{
   let empArr =  employeeData.filter((data)=> data.name != projectLead[0].name )
   let teamArr =  teamMemberData.filter((data)=> data.user.name != projectLead[0].name )
    setData({...data, projectLead: {}})
    setUserList(user)
    setTeamMemberData(teamArr)
    setProjectLead([])
    setEmployeeData(empArr)
  }
  // if(e[0] !== undefined){
  //   if(formType === 'edit'){
  //     let empArr = [...employeeData]
  //     let arr = [...teamMemberData]
  //     let obj = {
  //       id: e[0]?.id,
  //       email: e[0]?.email,
  //       name: e[0]?.name
  //     }
  //     arr.push({id: e[0].id, user: {id: e[0].id,name: e[0].name}, allocatedHrs : '00:00',projectLead: true})
  //     empArr.push(e[0])
  //     setIsOpenUser(false)
  //     setEmployeeData(e)
  //     setValidProjectLead(false)
  //     setData({...data, projectLead: obj})
  //     setProjectLeadTable(true)
  //     setProjectLead([obj])
  //     setEmployeeData(empArr)
  //     setTeamMemberData(arr)
  //     setTeamMemberTable(true)
  //   }
  //   else{
  //     let arr = [{ id: e[0]?.id, name: e[0]?.name}]
  //     let obj = {
  //       id: e[0]?.id,
  //       email: e[0]?.email,
  //       name: e[0]?.name
  //     }
   
  //     setValidProjectLead(false)
  //     setData({...data, projectLead: obj})
  //     setProjectLeadTable(true)
  //     setProjectLead([obj])
  //     setEmployeeData(e)
  //     setTeamMemberData([{name: e[0].name, id: e[0].id, user: {id: e[0].id}, allocatedHrs : '00:00'}])
  //     setTeamMemberTable(true)
  //   }
   
  // }
  // else{
  //   let emprr =  employeeData.filter((data)=> data.name != projectLead[0].name )
  //   let teamArr =  teamMemberData.filter((data)=> data.user.name != projectLead[0].name )
  //   setData({...data, projectLead: {}})
  //   setUserList(user)
  //   setProjectLeadTable(false)
  //   setTeamMemberData(teamArr)
  //   setProjectLead([])
  //   setEmployeeData(emprr)
  // }
   
}
const handleTeamMemberClick = (e) =>{
  if(formType === 'edit' && e.length !== data.teamMembers){
    let arr = [...data.teamMembers]
    const exisitingAmenIdx = arr.filter(item => item.user.name === data?.projectLead?.name).length
    exisitingAmenIdx === 0 ? arr.push(data.projectLead) : arr
    const modifiedEmployees = arr?.map(obj => {
      const parts = obj?.allocatedHrs?.toString().split(':');
      const mins = parts !== undefined ? parseInt(parts[0], 10) : ''
      return { ...obj, allocatedHrs: mins };
     });
    const teamMember =  modifiedEmployees?.map((item ,index) => item)
    let memberArr = teamMember.filter(a => e.some(b => a.user.id === b.id));
    var res = e.filter(item1 => !memberArr.some(item2 => (item2.user.name === item1.name)))
    res.map(item => memberArr.push({id: item.id, user: {id: item.id , name: item.name}}))
    setIsOpenUser(false)
    setEmployeeData(e)
    setData({...data , teamMembers: memberArr })
    setTeamMemberData(memberArr)
  }
  else if(formType === 'add'){
    let tableArr = []
      e.map(item => tableArr.push({name: item.name, id: item.id, user: {id: item.id,name: item.name}, allocatedHrs : '00'}) )
      const doesAlreadyExist = teamMemberData.find((item) => e.find(el => item.id === el.id));
      const index = tableArr !== undefined && tableArr.findIndex(
      (obj) => obj?.id === doesAlreadyExist?.id);
      if (index !== -1) {
      tableArr.splice(index, 1, doesAlreadyExist);
      }
    setTeamMemberData(tableArr)
    setTeamMemberTable(true)
    setIsOpenUser(false)
    setData({...data , teamMembers: tableArr })
    setEmployeeData(e)
    let arr =  user.filter(item => !e.some(itemToBeRemoved => itemToBeRemoved.id === item.id))
    setUserList(arr)
    setValidProjectMember(false)

  }
 
  else if(teamMemberData.length === 0){
    setTeamMemberTable(false)
  }
}
const handleProjectChange = (e) =>{
  if(e !== ''){
    setValidProjectName(false)
  }
  setData({ ...data, name : e.target.value }); 
}
const closeOutsideProject = () =>{
  setIsOpenProject(false)
}
const handleOpenProject = () =>{
  setIsOpenProject(true)
}
const closeOutsideUser = () =>{
  setIsOpenUser(false)
}
const handleOpenUser = () =>{
  setIsOpenUser(true)
}
const handleTime = (e) =>{
  let value = e.target.value;
  value = value.replace(/[^0-9:]/g, "");
  setData({ ...data, allocatedHrs : value }); 
  setAllocatedHrs(value)
}

const handleStartDateChange = (date) =>{
  if(date) {
    let newDate = new Date(date)
    let stringDate = newDate && newDate.toLocaleDateString('en-GB')
    setData({...data , startDate: stringDate})
  }
  else {
   setData({ ...data, startDate : null }); 
  }
}
const handleEndDateChange = (date) =>{
  if(date) {
    let newDate = new Date(date)
    let stringDate = newDate && newDate.toLocaleDateString('en-GB')
    setData({...data , endDate: stringDate})
  }
  else {
   setData({ ...data, endDate : null }); 
  }
}

const onChangeInput = (e, employeeId) => {
  const { name, value } = e.target
  let editData = []
   editData = teamMemberData.map((item) =>
   item.id === employeeId && name ? { ...item, [name]: value} : item
  )
  let totalHrs = editData.reduce((a,v) =>  a = parseInt(a) + parseInt(v.allocatedHrs) , 0 )
  setTeamMemberData(editData)
 setData({...data, teamMembers: editData})
 if(parseInt(data.allocatedHrs) < totalHrs){
  setErrMsg('Total of allocated hours for team members should not be greater than allocated hours')
  setShowInputErr(true)
 }
 else{
  setShowInputErr(false)
  setErrMsg('')
 }
}
return (
    <Fragment>
       <Form onSubmit={onSubmit}>
            <FormGroup>
                <Label for="exampleEmail">
                 Project Name
                </Label>
                <Input
                invalid = {projectNameValid}
                value={data?.name}
                id="exampleEmail"
                name="name"
                placeholder="Enter Project Name"
                type="text"
                onChange={handleProjectChange}
                />
                <FormFeedback>
                   Please enter project name
                </FormFeedback>
            </FormGroup>
            <FormGroup>
                <Label for="exampleEmail">
                 Allocated Hours
                </Label>
                <Input
                    type= "number"
                    value = {allocatedHrs}
                    onChange={handleTime}
                    placeholder="Enter Allocated Hours"
                  />
            </FormGroup>
            <div>
                <Row>
                    <Col>
                      <FormGroup>
                          <Label for="exampleEmail">
                          Start Date
                          </Label>
                          <DatePicker 
                          id='date'
                          locale="en-gb"
                          isClearable
                          selected={data?.startDate && new Date((moment(data?.startDate, 'DD/MM/YYYY').format()))} 
                          onChange={(date) => handleStartDateChange(date)} 
                          dateFormat="dd/MM/yyyy"/>
                      </FormGroup>
                     </Col>
                     <Col>
                      <FormGroup>
                          <Label for="exampleEmail">
                          End Date
                          </Label>
                          <DatePicker 
                          id='date'
                          locale="en-gb"
                          isClearable
                          selected={data?.endDate && new Date((moment(data?.endDate, 'DD/MM/YYYY').format()))} 
                          onChange={(date) => handleEndDateChange(date)} 
                          dateFormat="dd/MM/yyyy"/>
                      </FormGroup>
                     </Col>
            </Row>
            </div>
            <FormGroup>
                <Label for="examplePassword">
                 Project Lead
                </Label>
                <Typeahead
                  isInvalid={projectLeadValid}
                  id="basic-typeahead-single"
                  emptyLabel="No match found"
                  onFocus={handleOpenProject}
                  onBlur={closeOutsideProject}
                  open= {isOpenProject}
                  className='dropdownComp'
                  labelKey="name"
                  onChange={handleLeadClick}
                  options={JSON.parse(localStorage.getItem('user'))?.role === 'Team Lead' ? userForTeamLead : userList}
                  clearButton
                  placeholder="Select Project Lead"
                  selected={data.projectLead?.user?.name ? [data?.projectLead?.user?.name] : []}
                />
                  <FormFeedback>
                    Please select project lead
                 </FormFeedback>
            </FormGroup>
            <FormGroup style={{paddingBottom: isOpenUser ? '80px' : 0}}>
            <Label for="examplePassword">
                 Team Member
                </Label>
                <Typeahead
                  id="userDropdown"
                  onBlur= {closeOutsideUser}
                  onFocus={handleOpenUser}
                  open= {isOpenUser}
                  labelKey="name"
                  multiple
                  isInvalid={projectMemberValid}
                  onChange={handleTeamMemberClick}
                  options={JSON.parse(localStorage.getItem('user'))?.role === 'Team Lead' ? userForTeamLead : userList}
                  placeholder="Select Team Member"
                  selected={employeeData}
                />
                <FormFeedback>
                   Please select team member
                </FormFeedback>
            </FormGroup>
            <FormGroup>
              {showTeamMemberTable && 
            <table className="inputTable">
        <thead>
          <tr>
            <th>Name</th>
            <th>Allocated Hours</th>
          </tr>
        </thead>
        <tbody>
          {teamMemberData.map(({ id, name, allocatedHrs,user }) => (
            <tr key={id}>
              <td>
                {formType === 'add' ? name : user.name}
              </td>
              <td>
                <Input
                  className='tableInput'
                  name="allocatedHrs"
                  value={allocatedHrs}
                  type="number"
                  onChange={(e) => onChangeInput(e, id)}
                  placeholder="Enter Hours"
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
}
            </FormGroup>
            {showInputErr && 
            <div style={{color: 'red'}}>
              <li>{errMsg}</li>
              </div>}
            <hr />
            <div className='createProjectBtn'>
            <Button color="secondary" onClick={closeModal} style={{marginRight: '10px'}}>Cancel</Button>
            <Button color="primary" type='submit'>{loading ? 'Saving' : 'Save'}</Button>
            </div>
            
        </Form>
    </Fragment>
  )
}
